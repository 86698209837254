@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
*,:after,:before { box-sizing:inherit; }
html { font-size:62.5%; box-sizing:border-box; background-color:#00172E; }
body { margin:0; padding:0; font-weight:400; line-height:1.5; font-family:'Lato',sans-serif; text-rendering:optimizeLegibility; color:#323440; position:relative; font-size:1.4rem; }
:focus:not(.focus-visible) { outline:none; }
a:not([href]):not([tabindex]),a:not([href]):not([tabindex]):hover { color:inherit; }
:focus:not(.focus-visible) { outline:none; }
.image,img { max-width:100%; vertical-align:middle; }
svg { vertical-align:middle; fill:currentColor; }
sup { font-size:50%; }

.btn-primary { 
    border-radius:.4rem; line-height:2rem; display:inline-block; align-items:center; vertical-align:middle; padding:1.4rem 2.4rem; text-decoration:none; border-color:#FC5068; background:linear-gradient(90deg, #F6271C 0%, #E91E63 71.97%), #FFFFFF; color:white; text-align:center;
    &:hover { color:white; }
}

.ln {
    &-bg { z-index:0; width:100vw; height:100vh; position:fixed; top:0; left:0; background-size:cover; background-repeat:no-repeat; background-position:0 100%; }
    &-wrapper { display:flex; flex-direction:column; min-height:100vh; position:relative; z-index:1; }

    /* header */
    &-header { 
        display:flex; padding:1.6rem;
        @media (min-width:1024px) { padding:3.2rem; }
    }
    &-logo { 
        display:block; width:140px; height:36px;
        svg { width:100%; height:100%; fill:#ffffff; }
    }

    /* middle */
    &-middle { 
        flex:1; display:flex; align-items:center; flex-direction:column; width:100%; justify-content:center;
        @media (min-width:768px) { flex-direction:row; }
    }
    &-illu { 
        width:100%;
        @media (min-width:768px) { width:50%; max-width:500px; }
        @media (min-width:1024px) { width:50%; max-width:600px; }
        @media (min-width:1440px) { width:50%; max-width:700px; }
        &-2 {
            width:62px;
            @media (min-width:1024px) { width:124px; }
            img { width:100%; }
        }
        img {
            width:100%; max-width:450px;
            @media (min-width:768px) { max-width:100%; }
        }
    }
    &-content { 
        width:100%; margin:0 auto; color:#ffffff; font-size:1.4rem; flex:1; padding:0 1.6rem;
        @media (min-width:768px) { padding:0 0 0 1rem; }
        &-i { display:flex; }
        h1 { 
            font-size:2.4rem; line-height:3rem; font-weight:300; margin:0 0 3.2rem 0; padding:0 0 0 2.4rem; position:relative;
            @media (min-width:768px) { font-size:3.2rem; line-height:4rem; margin:0 0 4.8rem 0; padding:0 0 0 4rem; }
            &:before { content:''; display:block; background-color:#ffffff; width:0.8rem; height:100%; position:absolute; left:0; top:0; border-radius:0.4rem; }
            sup { font-weight:700; }
            strong { font-weight:900; }
        }
        p { 
            margin:0 0 2.4rem 0;
            a { color:#ffffff; }
        }
    }
    &-desc { 
        flex:1; padding:0 1.6rem 0 2.4rem; font-size:1.6rem; line-height:2.4rem;
        @media (min-width:768px) { font-size:1.8rem; line-height: 2.8rem; }
        @media (min-width:1024px) { padding:0 4.8rem; }
        p {
            margin:0 0 1.6rem 0;
            @media (min-width:768px) { margin:0 0 3.2rem 0; }
        }
    }

    /* footer */
    &-footer { 
        color:#ffffff; padding:1.6rem; display:flex; justify-content:space-between; flex-direction:column;
        @media (min-width:1024px) { flex-direction:row; padding:3.2rem; }
        &-list { 
            padding:0; margin:0 0 1.6rem 0; list-style:none; flex:1; display:flex; flex-direction:column; font-size:1.4rem; line-height:1.6rem;
            @media (min-width:768px) { flex-direction:row; margin:0; flex-wrap:wrap; padding-right:100px; font-size:1.6rem; }
        }
        &-item { 
            margin:0 0 8px 0;
            @media (min-width:768px) { 
                display:block; padding:0 8px 0 0; margin:0 8px 8px 0; border-right:1px solid #ffffff;
                &:last-child { border-right:none; }
            }
            &.assistance a { margin-top:5px; }
        }
        &-link { 
            color:#ffffff; text-decoration:none; line-height:1.9rem; display:inline-block;
            &:hover { color:#ffffff; text-decoration:underline; }
        }
        &-logo-relx { display:block; width:120px; height:30px; }
        svg { fill:#ffffff; width:100%; height:100%; }
    }
}